// Remove this file after storefront's domain migration

// tracking.js is not tree shakeable and double the compressed size of the bundled migrationscript.js
import { setCookie } from '@soundtrackyourbrand/tracking.js'

export type MigrationSuccess = {
  outcome: 'success'
  cookies?: Record<string, string>
  localStorage?: Record<string, any>
}

export type MigrationResult =
  | MigrationSuccess
  | { outcome: `redirecting-to-${'source' | 'destination'}`; url: URL }
  | { outcome: 'already-migrated' }
  | { outcome: 'wont-migrate' }
  | { outcome: 'disallowed-origin'; origin: string }
  | { outcome: 'inactive' }

const enum TriggerValue {
  NothingToMigrate = 'empty',
  Disable = 'disable',
}

const enum MarkerValue {
  RedirectedFromDestination = 'redirected',
  Skipped = 'skipped',
  Success = 'success',
  Error = 'error',
}

// The default values are the production values for storefront
export async function handleDomainMigration({
  /**
   * Force redirect from source to destination?
   * If `true` then `?domain-migration` must be present on source to trigger flow.
   */
  requireTrigger = true, // Requiring the trigger allows us to soft launch the code and test it in production
  /** The fully qualified URL to migrate data FROM */
  source = 'https://www.soundtrackyourbrand.com',
  /** Source will redirect to this destination, can be overridden by passing an allowed origin to `triggerQueryParam` */
  destination = 'https://www.soundtrack.io',
  /** RegExp used to determine if the current URL is a valid destination to migrate data TO */
  allowedDestinations = /^https:\/\/(www\.soundtrack\.io|(?:[^.]+\.)?storefront-next\.pages\.dev)$/,
  /** URL for business cloudflare worker that acts as the storage middle man for migration data */
  // kvEndpoint = 'https://app.soundtrack.io/migrator', // Not used for storefront migration
  /** Cookie names to import from source to destination */
  cookieNames = /^(syb\.(consent1|exp|source|medium|campaign|content|clids)|mp_super|voucher|AF_SYNC|afUserId|_fbp|_fbc|_uetsid|_uetvid|hubspotutk)$/,
  /** max-age for cookies set on destination (in seconds) */
  cookieMaxAge = 90 * 86400, // X days
  /** Local storage keys to import from source to destination */
  localStorageKeys = /^experiments$/,
  /**
   * Local storage key to use to store migration state on source & destination. Possible values:
   * - 'success': Destination and migration already completed
   * - 'error': Destination and migration already attempted but failed
   * - string: Source and migration to the URL in the string already completed/attempted
   * @see {@link MarkerValue}
   */
  localStorageMarker = 'syb:domain-migration',
  /** Abort import if any of local storage key matching `localStorageKeys` has a value on destination */
  skipIfLocalStoragePopulated = true,
  /**
   * Name of query parameter that is used to trigger and pass data between source and destination. Possible values:
   * - 'empty': No data to migrate - considered a success
   * - 'disable': Disable migration flow
   * - string:
   *   - Starting with http: Specify custom destination URL (must be allowed by `allowedDestinations`)
   *   - Otherwise: KV ID of data to migrate
   * @see {@link TriggerValue}
   */
  triggerParamName = 'domain-migration',
}): Promise<MigrationResult> {
  const currentUrl = new URL(window.location.href)
  const currentOrigin = currentUrl.origin
  let triggerParam = currentUrl.searchParams.get(triggerParamName)
  const triggerPresent = triggerParam != null
  const sourceUrl = new URL(source)
  const isSourceUrl = currentOrigin === sourceUrl.origin
  const isDestinationUrl = allowedDestinations.test(currentOrigin)
  // Allow destination to be overridden by specifying a URL in trigger param
  if (triggerParam?.startsWith('http')) {
    destination = triggerParam
    triggerParam = null
  }

  const storedMarker = localStorage.getItem(localStorageMarker)
  const getLocalStorageEntries = () =>
    Object.entries(localStorage).filter(
      ([key, value]) => localStorageKeys.test(key) && value && value !== '{}',
    )

  // Strip the trigger param from the current URL if present
  if (triggerPresent) {
    currentUrl.searchParams.delete(triggerParamName)
    history.replaceState(history.state, '', currentUrl.href)
  }

  // Bail out if disabled
  if (
    (requireTrigger && !triggerPresent) ||
    triggerParam === TriggerValue.Disable
  ) {
    return { outcome: 'inactive' }
  }

  // Step 1: Land on source
  // Step 3: Redirected to source from destination
  // = Serialize & persist data, then redirect to target with data ID & UTM params
  if (isSourceUrl) {
    const destinationUrl = new URL(destination)
    if (!allowedDestinations.test(destinationUrl.origin)) {
      throw new Error(`Invalid target origin: ${destinationUrl.origin}`)
    }
    destinationUrl.hash = currentUrl.hash
    destinationUrl.pathname = currentUrl.pathname
    destinationUrl.search = currentUrl.search
    let outgoingTriggerParam: string = TriggerValue.NothingToMigrate
    /*
      Only migrate data once to a destination, otherwise we would keep logging
      users who clear their localStorage back in on every visit.
    */
    if (storedMarker !== destinationUrl.origin) {
      const payload = {
        cookies: {} as Record<string, string>,
        localStorage: Object.fromEntries(getLocalStorageEntries()),
      }
      const existingCookies = new URLSearchParams(
        document.cookie.replaceAll('&', '%26').replaceAll('; ', '&'),
      )
      for (const [name, value] of existingCookies.entries()) {
        if (cookieNames.test(name)) payload.cookies[name] = value
      }
      // Include UTM params in redirect URL instead of cookies, to let tracking SDKs pick them up correctly
      const hasUtmParams = currentUrl.searchParams.get('utm_source')
      for (const param of ['source', 'medium', 'campaign', 'content']) {
        const cookieName = 'syb.' + param
        const paramValue = hasUtmParams
          ? currentUrl.searchParams.get('utm_' + param)
          : payload.cookies[cookieName]
        if (paramValue) {
          destinationUrl.searchParams.set('utm_' + param, paramValue)
          delete payload.cookies[cookieName] // omit from
        }
      }
      // const req = await fetch(kvEndpoint, {
      //   method: 'POST',
      //   headers: { 'Content-Type': 'application/json' },
      //   body: JSON.stringify(payload),
      // })
      // const reqBody = await req.text()
      // if (req.status > 300) {
      //   throw new Error(reqBody)
      // }
      try {
        outgoingTriggerParam = jsonEncodeAndLimitSize(payload)
      } catch (err) {
        localStorage.setItem(localStorageMarker, MarkerValue.Error)
        throw err
      }
    }
    destinationUrl.searchParams.set(triggerParamName, outgoingTriggerParam)
    localStorage.setItem(localStorageMarker, destinationUrl.origin)
    return { outcome: 'redirecting-to-destination', url: destinationUrl }
  }

  if (isDestinationUrl) {
    // Already migrated or no migration necessary = bail out
    if (
      currentOrigin === sourceUrl.origin || // Avoid redirect loop
      (storedMarker &&
        storedMarker !== MarkerValue.RedirectedFromDestination) ||
      triggerParam === TriggerValue.NothingToMigrate ||
      (skipIfLocalStoragePopulated && getLocalStorageEntries().length)
    ) {
      if (!storedMarker) {
        localStorage.setItem(localStorageMarker, MarkerValue.Skipped)
      }
      return { outcome: 'already-migrated' }
    }

    // Step 2: Land on destination with no prior import attempt = initiate redirect flow
    if (!triggerParam) {
      // Avoid redirect loop by only redirecting to source once
      if (storedMarker === MarkerValue.RedirectedFromDestination) {
        return { outcome: 'already-migrated' }
      }
      // Due to an unpleasant UX, storefront won't migrate any state for users that visits the new domain directly
      localStorage.setItem(localStorageMarker, MarkerValue.Skipped)
      return { outcome: 'wont-migrate' }
      // sourceUrl.hash = currentUrl.hash
      // sourceUrl.pathname = currentUrl.pathname
      // sourceUrl.search = currentUrl.search
      // sourceUrl.searchParams.set(triggerParamName, currentOrigin)
      // return { outcome: 'redirecting-to-source', url: sourceUrl }
    }

    // Step 4: Destination receives KV ID from source = retrieve and apply data
    // const reqUrl = new URL(kvEndpoint)
    // reqUrl.searchParams.set('id', triggerParam)
    // console.log('handleDomainMigration KV URL', reqUrl.href)
    try {
      // const req = await fetch(reqUrl)
      const reqBody = triggerParam
      // if (req.status > 300) {
      //   throw new Error(reqBody || `Unexpected status: ${req.status}`)
      // }
      localStorage.setItem(localStorageMarker, MarkerValue.Success)
      const data = JSON.parse(reqBody) as Omit<MigrationSuccess, 'outcome'>
      if (data.cookies) {
        for (const [key, value] of Object.entries<string>(data.cookies)) {
          if (!cookieNames.test(key)) continue
          setCookie(key, value, {
            maxAge: cookieMaxAge,
            // This enables testing on localhost
            samesite: window.location.hostname === 'localhost' ? 'Lax' : 'None',
          })
        }
      }
      if (data.localStorage) {
        for (const [key, value] of Object.entries<string>(data.localStorage)) {
          if (!localStorageKeys.test(key)) continue
          localStorage.setItem(key, value)
        }
      }
      return { ...data, outcome: 'success' }
    } catch (error: any) {
      localStorage.setItem(localStorageMarker, MarkerValue.Error)
      throw error
    }
  }

  return { outcome: 'disallowed-origin', origin: currentOrigin }
}

// Tracking cookies ordered least important to most important
const cookies = [
  'hubspotutk',
  '_uetvid',
  '_uetsid',
  '_fbc',
  '_fbp',
  'afUserId',
  'AF_SYNC',
  'mp_super',
  'syb.clids',
]

/**
 * This function encodes the payload but removes cookies if needed to ensure the result is less than 31k to ensure the
 * query string + URL and utm parameters are within the limits of Cloudflare. The exact limit is ≈32.7k and going above
 * leads to a 400 Request Header Or Cookie Too Large error.
 *
 * The cookies are removed in reverse order of priority. A cookie is allowed to be max 4096bytes which means most cookies
 * would have to be close to this limit before cookies will be removed.
 */
export function jsonEncodeAndLimitSize(
  payload: Readonly<Omit<MigrationSuccess, 'outcome'>>,
): string {
  const result = JSON.stringify(payload)
  if (result.length < 31_000) {
    return result
  }
  // Make deep copy of payload to avoid changing the original object
  let newPayload = JSON.parse(JSON.stringify(payload))
  for (const cookie of cookies) {
    delete newPayload.cookies?.[cookie]
    const result = JSON.stringify(newPayload)
    if (result.length < 31_000) {
      return result
    }
  }
  throw new Error('Payload too large to encode')
}
